import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from "@emotion/styled";
import Container from "react-bootstrap/Container";

const SectionBackground = styled.div`
  background-color : ${props => props.theme.colors.accent_yellow70};
  color : ${props => props.theme.colors.gray_dark};
  min-height:758px;
  padding-bottom: 100px;
  padding-top: 100px;
  @media (max-width: 991px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
`

const SectionTitle = styled(Col)`
    font: 58px/62px Abril Fatface;
    margin-top: 10px;
    margin-bottom: 10px;
    min-height: 132px;
    max-width:900px;
    @media (max-width: 1199px) {
    font: 56px/60px Abril Fatface;
    }
    @media (max-width: 767px) {
    font: 2.5rem/3rem Abril Fatface;
    }
`

const SectionRow = styled(Row)`
   font: 28px/36px Roboto;
   margin-top:30px;
   margin-bottom:30px;
   margin-left:20px;
   margin-right:20px;
`

const SectionImage = styled.img`
   margin-bottom: 16px;
   @media(min-width:1200px){
    padding-left:50px;
   }
   @media(min-width:767px){
    margin-left:30px;
   }
`
const ItemTitle = styled.div`
  font: 28px/36px Roboto;
  color: ${props => props.theme.colors.gray_dark};
  margin-bottom: 15px;
  @media (max-width: 767px) {
    font: 24px/32px Roboto;
  }
`
const ItemContent = styled.div`
  font: 20px/28px Roboto;
  color: ${props => props.theme.colors.gray_dark};
  @media (max-width: 767px) {
    font: 16px/22px Roboto;
  }
`

export default function SubscriptionSection2() {
  return (      
  <SectionBackground>
      <Container>
            <SectionTitle>
              What our community says about their subscriptions
            </SectionTitle>
            <Row>
                <SectionRow>
                    <Col xs="12" md= "4" lg="3">
                        <SectionImage src="/page-subscription-section2-img1.png"/> 
                    </Col>
                    <Col xs="12" md="8" lg="9">
                    <ItemTitle>3 months subscriptions, 7 mentorships</ItemTitle>
                    <ItemContent>
                        <p>
                        <b>Umriya Afini</b><br/>
                        Aku mentoring 3 bulan dengan goal persiapan job interview dan masuk ke perusahaan yang aku inginkan. Dari pertemuan pertama Kak Mina sangat memotivasi, aku jadi lebih tau apa kemampuanku dan menjadi lebih percaya diri. Tips dan triknya sangat-sangat membantu untuk menjadi top candidate, sampai akhirnya sekarang aku udah diterima di perusahaan yang aku inginkan. Ini semua berkat mentoring dengan Kak Mina dan fasilitasi WomenWorks!
                        </p>
                    </ItemContent>
                    </Col >
                </SectionRow>
                <SectionRow>
                    <Col xs="12" md="4" lg="3">
                      <SectionImage src="/page-subscription-section2-img2.png"/> 
                    </Col>
                    <Col xs="12" md="8" lg="9">
                    <ItemTitle>1 month subscription, 3 mentorships</ItemTitle>
                    <ItemContent>
                        <p>
                        <b>Beffy Saskia </b><br/>
                        I had a new job this year and thought I needed to refresh my perspective. Mentorship session with Bu Uti and Mba Muara was definitely eye-opening and helps me reconnect with myself. You should try to subscribe, and once you do, believe you can’t stop learning like I do!
                        </p>
                    </ItemContent>
                    </Col>
                </SectionRow>
            </Row>
      </Container>
  </SectionBackground>
  )
} 