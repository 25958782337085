import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import styled from "@emotion/styled";
import {css} from "@emotion/core";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import Container from 'react-bootstrap/Container';
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const checklistContent = [
  'Get your unlimited and personalised guidance on your growth',
  'We’ll match you with your best matched mentor!',
  'Set your mentorship schedule',
  'You can pause and unsubscribe your subscription at any time',
  'Sign up with any available mentor, however often you’d like',
]

const SectionBackground = styled.div`
  background-color: ${props => props.theme.colors.gray_light_background};
  color: ${props => props.theme.colors.gray_dark};
  font: 20px/28px Roboto;
  position:relative;
  overflow: hidden;
`

const SectionTitle = styled.div`
  margin-top:160px;
  margin-bottom:35px;
  width:100%;
  text-align:center;
  font: 58px/62px Abril Fatface;
  @media(max-width:991px){
    margin-top:80px;
    margin-bottom:30px;
  }
  @media(max-width:767px){
    margin-top:20px;
    font: 2.5rem/3rem Abril Fatface;
  }
  @media(max-width:320px){
    font: 2rem/2.5rem Abril Fatface;
  }
`

const SectionImage = styled.img`
@media(max-width:1200px){
  width:100%;
  height:auto;
  object-fit: contain;
  object-position:center;
  overflow:hidden;
}
`

const SectionText = styled.div`
  font: 20px/28px Roboto;
  max-width:90%;
  margin-left:20px;
  .row {
    margin-bottom:5px;
  }
  @media(max-width:991px){
    margin-top:20px;
  }
  @media(max-width:1119px){
    margin-top:10px;
  }
  @media(min-width:1200px){
    margin-top:50px;
  }
`
const SectionButton = styled.div`
  margin-bottom:30px;
  margin-top:5px;
  @media(max-width:991px) {
    margin-bottom:40px;
  }
  @media(max-width:768px){
    width:100%;
  }
`

const CardTextChecklist = styled.div`
  font: 16px/22px Roboto;
  @media(min-width:768px){
    min-height: 88px;
  }
  @media(min-width:992px){
    min-height: 71px;
  }
  @media(min-width:1200px){
    min-height: 116px;
  }
`
const CardTextCheckIcon = styled(Col)`
  padding-right:0;
`
const FACheck = styled(FontAwesomeIcon)`
  font-size:1rem;
  color: ${props => props.theme.colors.secondary_green10};
`

const CardTextCheckContent = styled(Col)`
  max-width:550px;
  padding-left:7px;
  padding-right:5px;
  @media(max-width:1199px){
    max-width:395px;
  }
  @media(max-width:991px){
    max-width:689px;
  }
  @media(max-width:767px){
    max-width:90%;
  }
  @media(min-width:1200px){
    margin-bottom: 10px;
  }
`

const ButtonFill2 = styled(Button)`
  height: 60px;
  color: ${props => props.theme.buttons.primary_button_violet.normal.color} !important;
  background-color: ${props => props.theme.buttons.primary_button_violet.normal.background_color} !important;
  border-color: ${props => props.theme.buttons.primary_button_violet.normal.border_color} !important;
  font: 22px/22px Roboto;
  transition: 0.25s ease-out;
  &:hover {
    color: ${props => props.theme.buttons.primary_button_violet.hovered.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.hovered.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_violet.hovered.border_color} !important;
    box-shadow: 0px 3px 20px ${props => props.theme.buttons.primary_button_violet.hovered.shadow} !important;
    @media(min-width:1199px){
      margin-top:-3px;
      margin-bottom:3px;
    }
  }
  &:focus, &.focus, &:active, &.active {
    color: ${props => props.theme.buttons.primary_button_violet.pressed.color} !important;
    background-color: ${props => props.theme.buttons.primary_button_violet.pressed.background_color} !important;
    border-color: ${props => props.theme.buttons.primary_button_yellow_green.pressed.border_color} !important;
    box-shadow: none !important;
    @media(min-width:1199px){
      margin-top:-3px;
      margin-bottom:3px;
    }
  }
  @media(min-width:1200px){
    width:175px;
  }
  @media(max-width:1199px){
    width:100%;
  }
`
export default function SubscriptionSection2(props) {
  return (
  <SectionBackground>
      <Container>
              <SectionTitle>
              How do you benefit from Subscription?
              </SectionTitle>
            <Row css={css`@media(max-width:991px){flex-direction:column-reverse;}`}>
              <Col xs="12" lg="6">
              <SectionText>
                <Row>
                  You are entitled to unlimited mentorship sessions within the chosen timeframe (1 month or 3 months)! You can use your subscription package to meet as many mentors as possible or to build a long-term relationships with a mentor you vibe with!
                </Row>
                <Row>
                <SectionButton>
                      <OutboundLink href="http://www.bit.ly/WWSubscriptionReg">
                        <ButtonFill2>Sign up now</ButtonFill2>
                      </OutboundLink>
                </SectionButton>
                </Row>
                </SectionText>
             </Col>
              <Col xs="12" lg="6">
              <SectionImage src="/page-subscription-section3-img1.png" css={css`@media(min-width:991px){float:left;}`}></SectionImage>
              </Col>
            </Row>
            <Row>
            <Col xs="12" lg="6">
              <SectionImage src="/page-subscription-section3-img2.png" css={css`@media(min-width:991px){float:right;}`}></SectionImage>
              </Col>
              <Col xs="12" lg="6">
              <SectionText css={css`@media(min-width:1200px){margin-left:60px;}`}>
              <Row>
                <CardTextChecklist>
                {checklistContent.map((content) => 
                    <Row>
                        <CardTextCheckIcon xs="auto">
                        <FACheck icon={faCheck} />
                        </CardTextCheckIcon>
                        <CardTextCheckContent xs="auto">
                            {content}
                        </CardTextCheckContent>
                    </Row>
                )}
                </CardTextChecklist>    
              </Row>
                <Row>
                <SectionButton>
                      <OutboundLink href="http://www.bit.ly/WWSubscriptionReg">
                        <ButtonFill2>Sign up now</ButtonFill2>
                      </OutboundLink>
                </SectionButton>
                </Row>
                </SectionText>
             </Col>
            </Row>
      </Container>
  </SectionBackground>
  )
}

