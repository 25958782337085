import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from "../components/layout";
import Section1 from "../sections/subscription-section1";
import Section1Mobile from "../sections/subscription-section1-mobile";
import Section2 from "../sections/subscription-section2";
import Section3 from "../sections/subscription-section3";
import Section4 from "../sections/sign-up-for-mentorship-section3";
import Section5 from "../sections/index-section6-multiple-use";
import SEO from "../components/seo";

export default class Subscription extends React.Component {

  componentDidMount() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.innerHTML = `
    var anchor1 = document.getElementById('subscription-3-months-button');
    anchor1.onclick = function() {
            fbq('track', 'Subscription', {page: "Subscription", mentorship_type: "subscription", subscription_type: "3 Months", action : "clicked-sign-up-now"});
    };
    var anchor2 = document.getElementById('subscription-1-month-button');
    anchor2.onclick = function() {
            fbq('track', 'Subscription', {page: "Subscription", mentorship_type: "subscription", subscription_type: "1 Month", action : "clicked-sign-up-now"});
    };
    var anchor3 = document.getElementById('1on1-button');
    anchor3.onclick = function() {
            fbq('track', 'Subscription', {page: "Subscription", mentorship_type: "1on1", action : "clicked-learn-more"});
    };
    `
    ;
    document.body.appendChild(s);
  }

  render(){
  return (
    <Layout 
      LogoColor={props => props.theme.colors.secondary_green} 
      HeaderMenuColor={props => props.theme.colors.gray_dark} 
      firstSectionBGColor={props => props.theme.colors.white}

      //button
      HeaderButtonColor={props => props.theme.buttons.primary_small_button_yellow_gray.normal.color} 
      HeaderButtonBorder={props => props.theme.buttons.primary_small_button_yellow_gray.normal.border_color} 
      HeaderButtonBackground={props => props.theme.buttons.primary_small_button_yellow_gray.normal.background_color}
      
      HeaderButtonColorHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.color}
      HeaderButtonBorderHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.border_color} 
      HeaderButtonBackgroundHover={props => props.theme.buttons.primary_small_button_yellow_gray.hovered.background_color}

      HeaderButtonColorPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.color}
      HeaderButtonBorderPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.border_color} 
      HeaderButtonBackgroundPressed={props => props.theme.buttons.primary_small_button_yellow_gray.pressed.background_color}
    >

      <SEO title={"Sign Up for Subscription"} description={"Sign up for WomenWorks Subscription to meet as many mentors as possible or to build a long-term relationships with a mentor you vibe with"} />
      
      <Section1/>
      <Section1Mobile/>
      <Section2/>
      <Section3/>
      <Section4/>
      <Section5
        Background = {props => props.theme.colors.accent_yellow70}
        TextColor = {props => props.theme.colors.gray_dark2}
        ColorHeart = {props => props.theme.colors.accent_yellow_dark}

        ButtonColor={props => props.theme.buttons.primary_button_violet.normal.color} 
        ButtonBorder={props => props.theme.buttons.primary_button_violet.normal.border_color} 
        ButtonBackground={props => props.theme.buttons.primary_button_violet.normal.background_color}
        
        ButtonColorHover={props => props.theme.buttons.primary_button_violet.hovered.color}
        ButtonBorderHover={props => props.theme.buttons.primary_button_violet.hovered.border_color} 
        ButtonBackgroundHover={props => props.theme.buttons.primary_button_violet.hovered.background_color}

        ButtonColorPressed={props => props.theme.buttons.primary_button_violet.pressed.color}
        ButtonBorderPressed={props => props.theme.buttons.primary_button_violet.pressed.border_color} 
        ButtonBackgroundPressed={props => props.theme.buttons.primary_button_violet.pressed.background_color}
      ></Section5>
       </Layout>   
  )
}
}
